/* istanbul ignore file */
/* eslint-disable */
/* prettier-ignore */

/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { UseFormMethods } from 'react-hook-form';
// import { BsCalendar } from 'react-icons/bs';
import { Composition } from '@atomic-layout/emotion';
import { CSSInterpolation } from '@emotion/serialize';
import {
  FaCamera,
  FaChevronLeft,
  FaChevronRight, FaExclamationCircle, FaExclamationTriangle,
  FaImages,
} from 'react-icons/fa';

import Card from '../../components/Card';
import TimeLine from './components/TimeLine';
import IReturn from '../../interfaces/IReturn';
import Animate from '../../components/Animate';
// @ts-ignore
import Order from '../../assets/Order.png';
import Social from '../../assets/social.svg';
import Alert from '../../assets/alert.svg';
import { gutter, theme } from '../../constants';
// import { dateForHuman } from '../../utils/utils';
import { H2, H3, H4 } from '../../components/Headings';
import Img from '../../components/Img';
import NoveltysAndImages from './NoveltysAndImages';
import Button from '../../components/form/Button';

interface IProps {
  form: UseFormMethods<IReturn>;
  close: Function;
}

// TODO: remove all comment if no longer used- backup
// const addDays = (date: any, days: number) => {
//   const result = new Date(date);
//   result.setDate(result.getDate() + days);
//   return result;
// };

const styles: CSSInterpolation = {
  display: 'flex',
  alignItems: 'center',
  gap: '.8rem',
  width: '100%',
  minHeight: '70px',
  '.icon': {
    background: '#F2F2F2',
    borderRadius: '50%',
    position: 'relative',
    padding: '1rem',
    span: {
      position: 'absolute',
      background: theme.colors.secondary,
      width: '17px',
      height: '17px',
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      top: '0',
      right: '-3px',
      fontSize: '12px',
    },
  },
  '&>div:nth-child(2)': {
    flex: '1',
  },
};

const commonCardStyles: CSSInterpolation = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '.8rem',
  width: '100%',
  minHeight: '70px',
  padding: '0 2rem',
  textAlign: 'left',
  '.icon': {
    background: '#F2F2F2',
    borderRadius: '50%',
    position: 'relative',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    span: {
      position: 'absolute',
      background: theme.colors.secondary,
      width: '17px',
      height: '17px',
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      top: '0',
      right: '-3px',
      fontSize: '12px',
      textAlign: 'left',
    },
  },
  '.text-content': {
    textAlign: 'left',
    flex: 1,
  },
};


const FormTracking = (props: IProps) => {
  const { form, close } = props;
  const [showProducts, setShowProduct] = useState(false);
  const [showNoveltys, setShowNoveltys] = useState(false);
  const formValues = form.getValues();
  const hasNovelty = formValues.order.comments?.some(comment => comment.type === 'NOVELTY');

  // const dateEstimate = addDays(formValues.order.created, 2);

  return (
    <div css={{ display: 'flex', justifyContent: 'center' }}>
      {!showProducts && !showNoveltys && (
        <Composition width="98%" maxWidth="650px" gap={gutter}>
          <Animate>
            <FaChevronLeft size={30} color={theme.colors.primary} onClick={() => close()} />
            <H2>No. {formValues.order.uuid}</H2>
            {hasNovelty && formValues.order.status !== 'DELIVERED' && (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '0.8rem 1rem',
                  border: `1px solid #FFFFFF`,
                  borderRadius: '0.5rem',
                  backgroundColor: '#FFF',
                  marginBottom: '1rem',
                }}
              >
                <div css={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <div
                    css={{
                      width: '39px',
                      height: '39px',
                      borderRadius: '50%',
                      backgroundColor: '#D32F2F',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Img src={Alert} />
                  </div>
                  <H3 css={{ color: theme.colors.primary, fontSize: '16px', margin: 0 }}>NOVEDAD</H3>
                </div>
                <Button
                  variant="primary"
                  border={false}
                  compact
                  css={{ padding: '0.4rem 1rem', fontSize: '16px' }}
                  onClick={() => setShowNoveltys(true)}
                >
                  VER MÁS
                </Button>
              </div>
            )}
            <TimeLine form={form} />
            <Card>
              <Composition gap={8}>
                <H2 css={{ color: theme.colors.primary }}>TU PEDIDO:</H2>
                <Composition gap={gutter}>
                  {/* <H2 css={{ color: theme.colors.primary }}>Entrega estimada:</H2>
                <H3 icon={<BsCalendar color={theme.colors.secondary} />}>
                  {dateForHuman(dateEstimate.toString(), { weekday: 'long' })},{' '}
                  {dateForHuman(dateEstimate.toString(), {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </H3>
                <hr color="#EFEFEF" /> */}
                  {formValues.order.items.map(cargo => (
                    <div css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1.5rem',
                    }}>
                      <div css={{
                        display: 'flex',
                        background: '#E0E0E0;',
                        padding: '.8rem',
                      }}>
                        <FaCamera color="white" size={20} />
                      </div>
                      <Composition css={{ flex: 1 }}>
                        <H3
                          color={theme.colors.primary}>{cargo.description}</H3>
                        <H4>Cantidad: {cargo.quantity}</H4>
                      </Composition>
                      <div>$ {cargo.total}</div>
                    </div>
                  ))}
                </Composition>
              </Composition>
            </Card>
            <Card>
              <Composition gap={8}>
                <H2 css={{ color: theme.colors.primary }}>RECIBE: </H2>
                <H4>
                  {formValues.order.client?.name}
                  {formValues.order.client.last_name && ` ${formValues.order.client.last_name}`}
                </H4>
                <H4>
                  {formValues.order.client?.client_address_information?.address1}
                  {formValues.order.client?.client_address_information?.address2 &&
                    `, ${formValues.order.client?.client_address_information?.address2}`}
                </H4>
                <H4>
                  {formValues.order.client?.client_address_information?.city},{' '}
                  {formValues.order?.client?.client_address_information?.country}
                </H4>
                {formValues.order.delivered_to && (
                  <>
                    <hr color="#EFEFEF" />
                    <Composition templateCols="auto 1fr" gap={gutter}>
                      <H2 css={{ color: theme.colors.primary }}>Entregado
                        a:</H2>
                      <H4 css={{
                        display: 'flex',
                        alignItems: 'center',
                      }}> {formValues.order.delivered_to}</H4>
                    </Composition>
                  </>
                )}
              </Composition>
            </Card>
            <Card css={commonCardStyles} onClick={() => setShowNoveltys(true)}>
              <div>
                <FaImages size={24} />
              </div>
              <div>Novedades e imágenes</div>
              <div>
                <FaChevronRight />
              </div>
            </Card>
            <Card css={commonCardStyles} onClick={() =>
              window.open(
                'https://api.whatsapp.com/send/?phone=593969037941&text=Hola+quiero+saber+mas+de+mi+pedido&type=phone_number&app_absent=0', '_blank'
              )}>
              <div>
                <Img src={Social} css={{ width: '28px', height: '28px' }} />
              </div>
              <div>Servicio al Cliente</div>
              <div>
                <FaChevronRight />
              </div>
            </Card>
          </Animate>
        </Composition>
      )}
      {showProducts && (
        <div css={{ width: '98%', maxWidth: '650px', gap: '15px' }}>
          <Animate>
            <FaChevronLeft onClick={() => setShowProduct(false)} size={30}
                           color={theme.colors.primary} />
            <h2 css={{ textAlign: 'center' }}>Tu pedido</h2>
            <div css={{ display: 'flex', justifyContent: 'center' }}>
              <Img src={Order} />
            </div>
            <Card>
              <Composition gap={gutter}>
                {/* <H2 css={{ color: theme.colors.primary }}>Entrega estimada:</H2>
                <H3 icon={<BsCalendar color={theme.colors.secondary} />}>
                  {dateForHuman(dateEstimate.toString(), { weekday: 'long' })},{' '}
                  {dateForHuman(dateEstimate.toString(), {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </H3>
                <hr color="#EFEFEF" /> */}
                {formValues.order.items.map(cargo => (
                  <div css={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1.5rem',
                  }}>
                    <div css={{
                      display: 'flex',
                      background: '#E0E0E0;',
                      padding: '.8rem',
                    }}>
                      <FaCamera color="white" size={20} />
                    </div>
                    <Composition css={{ flex: 1 }}>
                      <H3 color={theme.colors.primary}>{cargo.description}</H3>
                      <H4>Cantidad: {cargo.quantity}</H4>
                    </Composition>
                    <div>$ {cargo.price}</div>
                  </div>
                ))}
              </Composition>
            </Card>
          </Animate>
        </div>
      )}
      {showNoveltys &&
        <NoveltysAndImages setShowNoveltys={setShowNoveltys} form={form} />}
    </div>
  );
};

export default FormTracking;
